import { Image } from "@chakra-ui/image";
import { Box, Center, Heading, SimpleGrid, Text } from "@chakra-ui/layout";
import { Section } from "../../../../components";
import chooseUs1 from "./assets/choose-us-01.svg";
import chooseUs2 from "./assets/choose-us-02.svg";
import chooseUs3 from "./assets/choose-us-03.svg";
import chooseUs4 from "./assets/choose-us-04.svg";

const WHYCHOOSEUS = [
  {
    body: "We offer a service that can help any business make filing accounts a simple process.",
    image: chooseUs1,
  },
  {
    body: "We aim to offer the most efficient service that allows our clients to pay less for their accountants.",
    image: chooseUs3,
  },
  {
    body: "Utilising our West Hampstead accountancy services will give you more time to concentrate on the important things related to your business, whilst we take care of your accounts.",
    image: chooseUs4,
  },
  {
    body: "We manage your tax returns, payroll services, VAT returns and offer advice that can save you thousands!",
    image: chooseUs2,
  },
];

export function WhyChooseUs() {
  return (
    <Section>
      <Center>
        <Heading
          size="2xl"
          textAlign="center"
          pt={20}
          pb={20}
          id="why-choose-us"
        >
          Why Choose Us?
        </Heading>
      </Center>
      <Text
        textAlign="center"
        fontSize={{ base: "16px", md: 18, lg: 20 }}
        pb={20}
        w={["90%", "90%", "90%", "75%"]}
        m="auto"
      >
        H and S Accountants are a small hands on professional firm of Chartered
        Accountants. We deal mainly with owner managed business and provide a
        hands on service. We only use qualified staff so you do not end up
        paying to train the staff. We offer a no obligation free of charge quote
        for our services so what have you got to lose by calling us?
      </Text>
      <Center>
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={10}>
          {WHYCHOOSEUS.map((item, index) => {
            return (
              <Box w="60%" m="auto" h="100%" key={index}>
                <Center>
                  <Image
                    w="10rem"
                    h="10rem"
                    mb={10}
                    src={item.image}
                    alt=""
                    overflow="hidden"
                    transition="1s"
                    _hover={{ transform: "scale(1.25)" }}
                  />
                </Center>
                <Text fontSize={{ base: "16px", md: 18, lg: 20 }} mb={20}>
                  {item.body}
                </Text>
              </Box>
            );
          })}
        </SimpleGrid>
      </Center>
    </Section>
  );
}
