export const validateField = async (schema, field, value) => {
  const obj = schema
    .validate({ [field]: value })
    .then(() => {
      const obj = {
        error: false,
        message: "",
      };

      return obj;
    })
    .catch((error) => {
      const obj = {
        error: true,
        message: error.message || "",
      };
      return obj;
    });
  return obj;
};
