import { useState } from "react";
import { Box, Center, Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Textarea } from "@chakra-ui/textarea";
import { Section } from "../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "emailjs-com";
import * as yup from "yup";
import { validateField } from "../../../../utils";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Collapse, useToast } from "@chakra-ui/react";

const schema = [
  yup.object().shape({
    firstName: yup.string().required("Please enter your first name"),
  }),
  yup.object().shape({
    lastName: yup.string().required("Please enter your last name"),
  }),
  yup.object().shape({
    emailAddress: yup
      .string()
      .email()
      .required("Please enter a valid email address"),
  }),
  yup.object().shape({
    phone: yup.string().required("Please enter your phone number"),
  }),
  yup.object().shape({
    message: yup.string().required("Please enter a message"),
  }),
];

const initialFormDetails = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phone: "",
  message: "",
};

export function Contact() {
  const [formDetails, setFormDetails] = useState(initialFormDetails);
  const [validation, setValidation] = useState({
    firstName: { error: false, message: "" },
    lastName: { error: false, message: "" },
    emailAddress: { error: false, message: "" },
    phone: { error: false, message: "" },
    message: { error: false, message: "" },
  });
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const formatFormDetails = (data) => {
    return {
      from_name: `${data.firstName} ${data.lastName}`,
      message: data.message,
      reply_to: data.emailAddress,
      phone: data.phone,
    };
  };

  function sendEmail(e) {
    e.preventDefault();

    const payload = formatFormDetails(formDetails);
    setLoading(true);
    try {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          payload,
          process.env.REACT_APP_USER_ID
        )
        .then(
          () => {
            setLoading(false);
            setFormDetails(initialFormDetails);
            toast({
              title: "Thank you",
              description: "Your messsage has been successfully sent.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          },
          (error) => {
            console.log(error.text);
          }
        );
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast({
        title: "Sorry, your message could not be sent.",
        description: "If this issue persists please call us instead.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <Section background="gray.200">
      <Center>
        <Heading id="contact" size="2xl" pt={20} pb={[10, 10, 20, 20]}>
          Contact Us
        </Heading>
      </Center>
      <Box pb={20}>
        <Box
          bg="white"
          borderRadius="xl"
          p={10}
          shadow="md"
          width={["100%", "100%", "75%", "75%"]}
          margin="auto"
        >
          <Text mb={4}>
            Please fill in your details below to send us a message:
          </Text>
          <VStack alignItems="start" w="100%" spacing={3}>
            <Input
              placeholder="First Name"
              isInvalid={
                validation.firstName.error && validation.firstName.message
              }
              id="firstname"
              label="First Name"
              required
              disabled={loading}
              value={formDetails.firstName}
              onBlur={async () => {
                const obj = await validateField(
                  schema[0],
                  "firstName",
                  formDetails.firstName || ""
                );
                setValidation({ ...validation, firstName: obj });
              }}
              onChange={(e) => {
                setFormDetails({ ...formDetails, firstName: e.target.value });
              }}
            />
            {validation.firstName.error && validation.firstName.message && (
              <Collapse
                in={validation.firstName.error && validation.firstName.error}
                animateOpacity
              >
                <Text fontSize="sm" pl={0.5} color="red">
                  {validation.firstName.message}
                </Text>
              </Collapse>
            )}
            <Input
              placeholder="Last Name"
              id="lastname"
              label="Last Name"
              isInvalid={
                validation.lastName.error && validation.lastName.message
              }
              required
              disabled={loading}
              value={formDetails.lastName}
              onBlur={async () => {
                const obj = await validateField(
                  schema[1],
                  "lastName",
                  formDetails.lastName || ""
                );
                setValidation({ ...validation, lastName: obj });
              }}
              onChange={(e) => {
                setFormDetails({ ...formDetails, lastName: e.target.value });
              }}
            />
            {validation.lastName.error && validation.lastName.message && (
              <Collapse
                in={validation.lastName.error && validation.lastName.error}
                animateOpacity
              >
                <Text fontSize="sm" pl={0.5} color="red">
                  {validation.lastName.message}
                </Text>
              </Collapse>
            )}
            <Input
              placeholder="Email Address"
              id="email"
              label="Email Address"
              isInvalid={
                validation.emailAddress.error && validation.emailAddress.message
              }
              required
              disabled={loading}
              value={formDetails.emailAddress}
              onBlur={async () => {
                const obj = await validateField(
                  schema[2],
                  "emailAddress",
                  formDetails.emailAddress || ""
                );
                setValidation({ ...validation, emailAddress: obj });
              }}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  emailAddress: e.target.value,
                });
              }}
            />
            {validation.emailAddress.error && validation.emailAddress.message && (
              <Collapse
                in={
                  validation.emailAddress.error && validation.emailAddress.error
                }
                animateOpacity
              >
                <Text fontSize="sm" pl={0.5} color="red">
                  {validation.emailAddress.message}
                </Text>
              </Collapse>
            )}
            <Input
              placeholder="Phone Number"
              id="phone"
              label="Phone number"
              isInvalid={validation.phone.error && validation.phone.message}
              required
              disabled={loading}
              value={formDetails.phone}
              onBlur={async () => {
                const obj = await validateField(
                  schema[3],
                  "phone",
                  formDetails.phone || ""
                );
                setValidation({ ...validation, phone: obj });
              }}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  phone: e.target.value,
                });
              }}
            />
            {validation.phone.error && validation.phone.message && (
              <Collapse
                in={validation.phone.error && validation.phone.error}
                animateOpacity
              >
                <Text fontSize="sm" pl={0.5} color="red">
                  {validation.phone.message}
                </Text>
              </Collapse>
            )}
            <Textarea
              placeholder="Message"
              id="message"
              label="Message"
              isInvalid={validation.message.error && validation.message.message}
              required
              disabled={loading}
              value={formDetails.message}
              onBlur={async () => {
                const obj = await validateField(
                  schema[4],
                  "message",
                  formDetails.message || ""
                );
                setValidation({ ...validation, message: obj });
              }}
              onChange={(e) => {
                setFormDetails({ ...formDetails, message: e.target.value });
              }}
            />
            {validation.message.error && validation.message.message && (
              <Collapse
                in={validation.message.error && validation.message.error}
                animateOpacity
              >
                <Text fontSize="sm" pl={0.5} color="red">
                  {validation.message.message}
                </Text>
              </Collapse>
            )}
            <Button
              isLoading={loading}
              loadingText="Submitting"
              onClick={async (e) => {
                console.log("clickewd");
                const firstNameValidation = await validateField(
                  schema[0],
                  "firstName",
                  formDetails.firstName || ""
                );
                const lastNameValidation = await validateField(
                  schema[1],
                  "lastName",
                  formDetails.lastName || ""
                );
                const emailAddressValidation = await validateField(
                  schema[2],
                  "emailAddress",
                  formDetails.emailAddress || ""
                );
                const phoneValidation = await validateField(
                  schema[3],
                  "phone",
                  formDetails.phone || ""
                );
                const messageValidation = await validateField(
                  schema[4],
                  "message",
                  formDetails.message || ""
                );
                setValidation({
                  firstName: firstNameValidation,
                  lastName: lastNameValidation,
                  emailAddress: emailAddressValidation,
                  phone: phoneValidation,
                  message: messageValidation,
                });
                if (
                  !validation.firstName.error &&
                  !validation.lastName.error &&
                  !validation.emailAddress.error &&
                  !validation.phone.error &&
                  !validation.message.error &&
                  formDetails.firstName &&
                  formDetails.lastName &&
                  formDetails.emailAddress &&
                  formDetails.phone &&
                  formDetails.message
                ) {
                  sendEmail(e);
                }
              }}
            >
              Submit
            </Button>
          </VStack>
          <VStack alignItems="center" w="100%" marginTop={10} spacing={3}>
            <Text textAlign="center">Alternatively, give us a call on:</Text>
            <Text as="a" href="tel:020 3174 1905">
              <Flex alignItems="center">
                <FontAwesomeIcon icon={faPhone} />
                <Text fontWeight="bold" textDecoration="underline" ml="10px">
                  020 3174 1905
                </Text>
              </Flex>
            </Text>
          </VStack>
        </Box>
      </Box>
    </Section>
  );
}
