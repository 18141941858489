import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Section } from "../../../../components";
import { smoothScroll } from "../../../../utils/smooth-scroll";
import heroImg from "./assets/hero.png";

export function Hero() {
  return (
    <Box
      id="hero"
      h="90vh"
      paddingTop="10vh"
      backgroundImage={heroImg}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="repeat"
    >
      <Section background="none">
        <Text
          fontSize={{ base: "20px", md: 18, lg: 20 }}
          fontWeight="800"
          color="white"
          textShadow="1px 1px #ff0000"
        >
          Accounting and Tax Services
        </Text>
        <Heading
          as="h1"
          fontSize={{ base: 45, md: "60px", lg: "70px" }}
          fontWeight="800"
          py={2}
          color="white"
          textShadow="1px 1px #ff0000"
        >
          H and S Accountants
        </Heading>
        <Text
          fontSize={{ base: "20px", md: 18, lg: 20 }}
          fontWeight="600"
          color="white"
          textShadow="1px 1px #ff0000"
        >
          Expert West Hampstead accountants serving North & Central London
        </Text>
        <Flex wrap>
          <Button
            size={["lg"]}
            mt={[10, 10, 20, 20]}
            mr={4}
            onClick={() => smoothScroll("#why-choose-us")}
          >
            Find Out More
          </Button>
          <Button
            colorScheme="blue"
            size="lg"
            display={["none", "none", "block", "block"]}
            mt={20}
            onClick={() => smoothScroll("#contact")}
          >
            Contact Us
          </Button>
        </Flex>
      </Section>
    </Box>
  );
}
