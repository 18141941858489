import { Header, Footer } from "../../components";
import { Contact, Hero, Services, Testimonies, WhyChooseUs } from "./sections";

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      <WhyChooseUs />
      <Services />
      <Testimonies />
      <Contact />
      <Footer />
    </>
  );
}
