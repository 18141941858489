import { Box, Flex, Text } from "@chakra-ui/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

export function Footer() {
  return (
    <Box bg={["blue.300"]} w="100%" p={2}>
      <Flex
        flexDirection={["column", "column", "column", "row", "row"]}
        justify="space-between"
        w="90%"
        m="auto"
        fontSize="sm"
      >
        <Flex alignItems="center">
          <Box>
            <Text>H and S Accountants Ltd © 2014 - Registered in England</Text>
            <Text>90 Mill Lane, West Hampstead, London, NW6 1NL</Text>
            <Text>Mr Chet Haria FCA, MSc and Mr David Summers FCA</Text>
            <Text>Company No. 4582684</Text>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="end"
          alignItems={["start", "start", "end", "end"]}
          textAlign={["left", "left", "right", "right"]}
        >
          <Flex alignItems="center">
            <FontAwesomeIcon icon={faPhone} />
            <Text ml="10px">020 3174 1905</Text>
          </Flex>
          <Flex alignItems="center">
            <FontAwesomeIcon icon={faEnvelope} />
            <Text ml="10px">hstaxplan@gmail.com</Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
