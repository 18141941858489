import { Box, Center, Flex, Heading, Text } from "@chakra-ui/layout";
import { Section } from "../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const TESTIMONIES = [
  {
    quote:
      "My accounts should be very simple, but my old accountant left me confused after just a few minutes. H and S Accountants Ltd sorted out my accounts with ease and now I even understand Profit and Loss and Balance Sheets.",
    role: "Sole Trader",
    color: "blue.400",
  },
  {
    quote:
      "We have always enjoyed a good relationship working with H and S Accountants Ltd. The team are always helpful and approachable and are able to explain technical tax and accountants terms in simple English. I would have no hesitation in recommending them to anyone.",
    role: "Director",
    color: "orange.500",
  },
  {
    quote:
      "They are a small friendly bunch of accountants and always happy to help. When ever I call the office there is always somewhere there who knows who I am and is able to advise me accordingly.",
    role: "Director",
    color: "gray.600",
  },
];

export function Testimonies() {
  return (
    <Section>
      <Center>
        <Heading size="2xl" textAlign="center" pt={20} pb={10} id="testimonies">
          What Our Clients Say About Us
        </Heading>
      </Center>
      <Flex flexDirection="column" alignItems="center">
        {TESTIMONIES.map((item, index) => {
          return (
            <Box
              color="white"
              w={["90%", "90%", "80%", "60%"]}
              bg={item.color}
              shadow="md"
              p={10}
              m={8}
              key={index}
              borderRadius="xl"
            >
              <Flex flexDirection="column">
                <Text fontSize={[16, 16, 20, 20]}>{item.quote}</Text>
                <Box textAlign="right" flex="end">
                  <Box background="white" h="1px" w="90%" m={4} />
                  <Flex alignItems="center" justifyContent="end">
                    <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                    <Text ml="10px" fontSize={[16, 16, 20, 20]}>
                      {item.role}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          );
        })}
        <Text mt={2} mb={10} fontStyle="italic">
          *due to client confidentiality, names of businesses and business
          owners/employees have not been disclosed.
        </Text>
      </Flex>
    </Section>
  );
}
