import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Home from "./pages/home/Home";
import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";

// init(process.env.REACT_APP_USER_ID);

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Route path="/" exact>
          <Home />
        </Route>
        <Redirect to="/" />
      </Router>
    </ChakraProvider>
  );
}

export default App;
