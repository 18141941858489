import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/layout";
import { Section } from "../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";

const SERVICES = [
  "Annual Accounts Preparation",
  "Business Start-up Assistance",
  "Payroll Services",
  "Preparation of VAT Returns",
  "Self Assessment Tax Returns",
  "Corporation Tax Returns",
  "Tax Investigation Service",
  "Bookkeeping Services",
  "CIS",
  "Company Secretary Services",
];

export function Services() {
  return (
    <Section background="gray.200">
      <Center>
        <Heading id="services" textAlign="center" size="2xl" py={20}>
          Our Services
        </Heading>
      </Center>

      <SimpleGrid columns={[1, 2, 2, 3]} spacing={3} pb={20}>
        {SERVICES.map((service, index) => {
          return (
            <Box
              w="100%"
              h="100%"
              bg="white"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              shadow="md"
              p={8}
              key={index}
              transition="500ms"
              _hover={{ bg: "blue.200", transform: "scale(1.05)" }}
            >
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <FontAwesomeIcon icon={faPiggyBank} size="5x" />
                <Text pt={10} fontSize={20} textAlign="center" m="auto">
                  {service}
                </Text>
              </Flex>
            </Box>
          );
        })}
      </SimpleGrid>
    </Section>
  );
}
