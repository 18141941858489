import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
import { Button, ButtonGroup } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { smoothScroll } from "../../utils/smooth-scroll";
import logo from "./assets/logo.png";

export function Header({ props }) {
  return (
    <>
      <Box
        bg={["blue.300"]}
        w="100%"
        height="40px"
        p={2}
        display={["none", "none", "none", "block"]}
      >
        <Flex justify="space-between" w="80%" m="auto" fontWeight="bold">
          <Flex alignItems="center">
            <FontAwesomeIcon icon={faMapMarker} />
            <Text ml="10px">90 Mill Lane, West Hampstead, London, NW6 1NL</Text>
          </Flex>
          <HStack spacing="3rem">
            <Flex alignItems="center">
              <FontAwesomeIcon icon={faPhone} />
              <Text ml="10px">020 3174 1905</Text>
            </Flex>
            <Flex alignItems="center">
              <FontAwesomeIcon icon={faEnvelope} />
              <Text ml="10px">hstaxplan@gmail.com</Text>
            </Flex>
          </HStack>
        </Flex>
      </Box>

      <Flex
        position="sticky"
        top="0"
        zIndex="10"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        p={4}
        shadow="md"
        bg={["white"]}
        {...props}
      >
        <Flex
          justify="space-between"
          w={["100%", "100%", "80%", "80%"]}
          m="auto"
          align="center"
        >
          <Image
            src={logo}
            alt="H and S Accountants Ltd"
            maxHeight={["2rem", "2rem", "3rem", "3rem"]}
            cursor="pointer"
            onClick={() => {
              smoothScroll("#root");
            }}
          />
          <Box>
            <ButtonGroup spacing={6}>
              <Button
                colorScheme="blue"
                variant="solid"
                onClick={() => smoothScroll("#contact")}
              >
                Contact Us
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
