import { Box } from "@chakra-ui/layout";

export function Section({ children, margin = false, background }) {
  return (
    <Box bg={background || "white"}>
      <Box width={["90%", "90%", "80%", "60%"]} m="auto" mb={margin && 36}>
        {children}
      </Box>
    </Box>
  );
}
